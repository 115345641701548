import React from "react";
const classes = require('./FlyerLay.module.scss');

const flyerLay = (props) => {
  let otClass = "otFalse";
  let tableClass = "tableFalse";
  if (props.OuterTitle != null) {otClass="otTrue"}
  if (props.ContentClass === "FlyerTable") {tableClass="tableTrue"}
  
  return (
    <div className={[classes.FlyerLay, classes[props.where], classes[props.addClass], classes[otClass], classes[tableClass]].join(' ')}>
      <div className={[classes[props.Wrap], classes[props.Wrap2]].join(' ')}>
        {props.OuterTitle ? <div className={classes.OuterTitle}>{props.OuterTitle}</div> : null}

        <div className={[classes.whitebox].join(' ')}>
          {props.InnerTitle ? <div className={classes.InnerTitle}>{props.InnerTitle}</div> : null}

          <div className={[classes.flContent, classes[props.ContentClass], classes[props.ccClass]].join(' ')}>
            <div className={classes[props.InnerWrap]}>
              {props.FlyerContent}
            </div>
            {props.fwRow ? <div className={classes.fwRow} style={{margin:[props.margin]}}>{props.fwRow}</div> : null}
            {props.FlyerContent2 ? <div className={[classes[props.InnerWrap], classes.fc2].join(' ')}>{props.FlyerContent2}</div> : null}
          </div>
        </div>
        {props.FinalImage ? <div className={[classes.fullSec, classes.imageRow].join(' ')}>{props.FinalImage}</div> : null}
      </div>
    </div>
  );
}
flyerLay.defaultProps = {
  Wrap: "medDef",
  ContentClass: "FlyerContent1",
  InnerWrap: "inside",
  margin:"10px 0 20px"
};
export default flyerLay;