import React from "react"
import { Helmet } from "react-helmet"
import classes from "./Plunge.module.scss"
import FlyerLay from "../../components/FlyerLay/FlyerLay"
import image from "../../assets/images/plunge.jpg"

const tixLink = "https://www.eventbrite.com/e/2025-pelican-plunge-tickets-1114537414409?aff=oddtdtcreator"

const plunge = (props) => {
  const OuterTitle = (
    <div>
      <h1>The Emerald Coast Foundation <br/><span>Pelican Plunge</span><br/>January 1 &middot; 11am</h1>
      <div className={classes.links}>
        <a href={tixLink} target="_blank" rel="noreferrer" className={[classes.ecLinkRev, classes.ecLinkSmX].join(' ')}>Get Tickets</a>
        <a href="#details" className={[classes.ecLink, classes.ecLinkSmX].join(' ')} data-uk-scroll>Details</a>
        <a href="#options" className={[classes.ecLinkRev, classes.ecLinkSmX].join(' ')} data-uk-scroll>Registration Options</a>
      </div>
    </div>
  )
  const FlyerContent = (
    <div>
      <p>Come celebrate the New Year with a plunge into the Gulf of Mexico at The Boardwalk's Annual Pelican Plunge! Whether you dip, dive, flop, or leap into the water, you'll 
      be freezin' for a reason on New Year's Day. 100% of proceeds directly support the children of The Emerald Coast Foundation… IT'S FOR THE KIDS!</p>
    </div>
  )

  const fwRow = (
    <div id="details" className={[classes.fullSec, classes.ddPlax].join(' ')}>
      <div className={[classes.insideN, classes.sectionWrap].join(' ')}>
        <div className={[classes.il, classes.one].join(' ')}>
          <p className={classes.bmargin0}>
            <b>When: January 1st &middot; 11am </b><br/><em>Check in begins at 9am</em>
          </p>
          <hr/>
          <a href={tixLink} target="_blank" rel="noreferrer" className={[classes.ecLinkRev, classes.ecLinkSm, classes.smBtn].join(' ')}>Get Tickets</a>
          <p className={classes.starter}><b>Starting with lively pre-plunge activities
            at The Boardwalk on Okaloosa Island.
          </b></p>
          <p className={classes.pleft1}>Warm-up with hot cocoa &amp; coffee</p>
          <p className={classes.pleft1}>Secure raffle tickets for a chance to win amazing prizes</p>
          <p className={classes.pleft1}>Partake in the costume contest</p>
          <p className={classes.pleft1}>Enjoy a live DJ before taking an exhilarating dip into the Gulf</p>
          <p className={classes.pleft}><b>*FIRST 100 People to Register Get a FREE 2025 Pelican Plunge T-Shirt</b></p>
        </div>

        <div className={[classes.il, classes.two].join(' ')}>
          <p><b>At 11:30am, all participants will line up near the water for the official countdown to dash into the Gulf of Mexico. </b></p>
          <p>Directly after, plungers will flock upstairs to The Crab Trap restaurant to get dry and toasty at the post-plunge party. 
          Warm up with The Crab Trap's famous gumbo, pour on the holiday fun with plunge-worthy drink specials, and mix and mingle to live music.</p>
          <p>The festivities also include a large raffle with thousands of dollars in prizes! All proceeds are raised to help local children in our community.</p>
          <div className={classes.spacer1} />
          <a href={tixLink} target="_blank" rel="noreferrer" className={[classes.ecLinkRev, classes.ecLinkSm, classes.lgBtn].join(' ')}>Get Tickets</a>
        </div>
      </div>
    </div>
  )

  const FlyerContent2 = (
    <div id="options" className={[classes.fullSec, classes.levels].join(' ')}>
      <h4>Ticket Options</h4>
      <div className={[classes.sectionWrap, classes.insideN].join(' ')}>
        <div className={[classes.fullSec, classes.group, classes.one].join(' ')}>
          <div className={[classes.fullSec, classes.item, classes.i1].join(' ')}>
            <p className={classes.levelName}>ADVANCED TICKET PURCHASE &middot; $25</p>
            <p>
              <b>Includes: Plunge, and After Party at The Crab Trap at The Boardwalk on Okaloosa Island, with free cocoa and gumbo. </b>
              <br/>
              All participants have access to purchase raffle tickets for prizes, and the first 100 people get a 2025 Pelican Plunge t-shirt.
            </p>
            {/* <div className={classes.spacer1} /> */}
            <a href={tixLink} target="_blank" rel="noreferrer" className={[classes.ecLink2, classes.ecLinkSm].join(' ')}>Get Tickets</a>
          </div>
        </div>

        <div className={[classes.fullSec, classes.group, classes.two].join(' ')}>
          <p className={classes.levelName}>KING CRAB VIP TICKET &middot; $400</p>
          <div className={classes.vip}>
            <p className={classes.bmargin0}><b>Limited availability. Includes:</b></p>
            <p>New Year's Eve stay at The Holiday Inn Resort on Okaloosa Island</p>
            <p>$200 in dining certificates to The Boardwalk on Okaloosa Island, to help you ring in the new year</p>
            <p>2 Pelican Plunge tickets &middot; 2 Pelican Plunge t-shirts</p>
            <p>A wellness welcome bag, sponsored by Spa Lilliana at Hotel Effie Sandestin</p>
            <div className={classes.spacer1} />
            <a href={tixLink} target="_blank" rel="noreferrer" className={[classes.ecLink2, classes.ecLinkSm].join(' ')}>Get Tickets</a>
          </div>
        </div>

        <div className={[classes.fullSec, classes.group, classes.three].join(' ')}>
          <p className={classes.levelName}>DAY-OF TICKETS &middot; $30</p>
          <p>
            <b>Includes: Plunge, and After Party at The Crab Trap at The Boardwalk on Okaloosa Island, with free cocoa and gumbo. </b>
            <br/>
            All participants have access to purchase raffle tickets for prizes, and the first 100 people get a 2025 Pelican Plunge t-shirt.
          </p>
        </div>
      </div>
    </div>
  )
  const FinalImage = (
    <img src={image} alt="Pelican Plunge" className={classes.fwImg} />
  )

  return (
    <div className={classes.Plunge}>
      <Helmet>
        <title>Annual Pelican Plunge | Emerald Coast Poker Run</title>
        <meta name="description" content="Read through this page to learn more about The Emerald Coast Foundation's Pelican Plunge!" />
      </Helmet>
      
      <FlyerLay where="PlungePage" OuterTitle={OuterTitle} FlyerContent={FlyerContent} fwRow={fwRow} FlyerContent2={FlyerContent2} FinalImage={FinalImage} />
    </div>
  )
}
export default plunge